import React from 'react';
import { SnackbarProvider } from 'notistack';
//import { useMediaQuery } from '@mui/material';
import { createTheme, responsiveFontSizes, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Router from './Router';

function Theme() {
  //const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const prefersDarkMode = true;
  
  const theme = React.useMemo(
    () => {
      let theme = createTheme({
        palette: {
          mode: prefersDarkMode ? 'dark' : 'light',
          /*
          primary: {
            main: '#556cd6',
          },
          secondary: {
            main: '#19857b',
          },
          background: {
            default: '#fff',
          },
          */
        },
      })
      return responsiveFontSizes(theme);
    },
    [prefersDarkMode],
  );

  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline />
        <Router />
      </SnackbarProvider>
    </ThemeProvider>
  );
}

export default Theme;