import { connectFunctionsEmulator, getFunctions } from 'firebase/functions';
import { initializeApp } from 'firebase/app';
import { getPerformance } from "firebase/performance";

export const FIREBASE_RESPONSE = {
  EMAIL_IN_USE: 'Email already in use',
  EMAIL_INVALID: 'Email is invalid',
  EMAIL_NOT_FOUND: 'Email was not found',
  PASSWORD_INVALID: 'Invalid credentials',
  USER_DISABLED: 'User disabled',
  TOO_MANY_REQUESTS: 'Too many attempts made, try again later',
  EXPIRED_ACTION_CODE: 'The invitation link has expired, get in touch with your administrator',
  INVALID_ACTION_CODE: 'The invitation link has expired, get in touch with your administrator',
  QUOTA_EXCEEDED_STORAGE: 'Internal server error, get in touch with your administrator',
  UNAUTHENTICATED_STORAGE: 'Unauthenticated, please authenticate and try again',
  UNAUTHORIZED_STORAGE: 'Unauthorized, you are not authorized to perform this action',
};

const firebaseConfig = {
  apiKey: "AIzaSyDZ4WTqAtVRj_VtcfYHcq9q7xnCOJ4fUU0",
  authDomain: "personal-health-dashboard.firebaseapp.com",
  projectId: "personal-health-dashboard",
  storageBucket: "personal-health-dashboard.appspot.com",
  messagingSenderId: "142076398480",
  appId: "1:142076398480:web:e63bb6e2dd46d17756c115",
  measurementId: "G-YSL578MD7R"
};

const firebaseApp = initializeApp(firebaseConfig);

getPerformance(firebaseApp);

if (process.env.REACT_APP_ENABLE_EMULATOR === "true") {
  connectFunctionsEmulator(getFunctions(firebaseApp), "localhost", 5001);
}

export default firebaseApp;