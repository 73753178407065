import './App.css';
import { useGetDashboardDataQuery } from './app/services/dashboard/api';
import { CircularProgress, Card, CardContent, Grid, Typography, Box, Fab, useTheme } from '@mui/material';
import { ApiError } from './app/models/ApiError';
import { useGetDailyNutrutionQuery } from './app/services/cronometer/api';
import { Variant } from '@mui/material/styles/createTypography';
import { parse } from 'csv-parse/browser/esm/sync';
import { useLazyGetVersionQuery } from './app/services/config/api';
import package_json from '../package.json';
import { useEffect } from 'react';
import Battery0BarIcon from '@mui/icons-material/Battery0Bar';
import Battery1BarIcon from '@mui/icons-material/Battery1Bar';
import Battery2BarIcon from '@mui/icons-material/Battery2Bar';
import Battery3BarIcon from '@mui/icons-material/Battery3Bar';
import Battery4BarIcon from '@mui/icons-material/Battery4Bar';
import Battery5BarIcon from '@mui/icons-material/Battery5Bar';
import Battery6BarIcon from '@mui/icons-material/Battery6Bar';
import BatteryFullIcon from '@mui/icons-material/BatteryFull';
import HotelIcon from '@mui/icons-material/Hotel';
import RefreshIcon from '@mui/icons-material/Refresh';

function App() {
  const theme = useTheme();
  
  // Poll the APIs every 5 minutes
  const dataPollingInterval = 300000;
  const { data: dashboardData, error: dashboardError, isLoading: dashboardIsLoading } = useGetDashboardDataQuery(undefined, {
    pollingInterval: dataPollingInterval
  });

  const { data: dailyNutritionData, error: dailyNutritionError, isLoading: dailyNutritionIsLoading } = useGetDailyNutrutionQuery(undefined, {
    pollingInterval: dataPollingInterval
  });

  const versionCheckPollingInterval = 30000;
  const [ triggerGetVersion, getVersionResult ] = useLazyGetVersionQuery({
    pollingInterval: versionCheckPollingInterval
  });

  useEffect(() => {
    async function triggerVersionCheck() {
      // Need to delay for 30 seconds when the page first load before triggering a version check. If we don't do this and immiditely check
      // for the version, the deploying code for the app or update to the firestore database might be behind and cause a constant refreshing
      // of the page.
      await delay(versionCheckPollingInterval);
      await triggerGetVersion();
    }
    triggerVersionCheck();
  });  
  
  if (!getVersionResult.isLoading && !getVersionResult.isUninitialized) {
    let serverVersion: string | undefined;
    let packageVersion: string;
    let vError : ApiError = {};
    if (getVersionResult.error) {
      vError = getVersionResult.error as ApiError;
      console.error(vError.data);
    } else if (getVersionResult.data?.status === 'error') {
      console.error(getVersionResult.data?.data);
    } else {
      serverVersion = getVersionResult.data?.data;
      packageVersion = package_json.version;
      console.debug(`Package Version: ${packageVersion}`);
      console.debug(`Server Version: ${serverVersion}`);

      if (serverVersion !== packageVersion) {
        console.log('Reloading page to get latest version of code');
        window.location.reload();
      }
    }
  }
  

  let caloriesConsumed : number = 0;
  let dnError: ApiError = {};
  if (dailyNutritionError) {
    dnError = dailyNutritionError as ApiError;
  } else {
    if (dailyNutritionData?.data) {
      const records: any[] = parse(dailyNutritionData.data, {
        columns: true,
        skip_empty_lines: true
      });
      if (records.length > 0) {
        caloriesConsumed = records[0]?.["Energy (kcal)"] ?? 0;
      }
    }    
  }

  const deficitGoal: number = 500;

  let dashError : ApiError = {};
  let remainingCalories: number = 0;
  let remainingCaloriesWithDeficitGoal: number = 0;
  if (dashboardError) {
    dashError = dashboardError as ApiError;
  } else if (dashboardData?.status === 'error') {
    dashError.data = dashboardData?.data;
    console.error(dashboardData?.data);
  } else {
    const activeCaloriesBurned = dashboardData?.data?.activeCaloriesBurned ?? 0;
    const bmrCaloriesBurned = dashboardData?.data?.bmrCaloriesBurned ?? 0;
    remainingCalories = (activeCaloriesBurned + bmrCaloriesBurned) - caloriesConsumed;
    remainingCaloriesWithDeficitGoal = (activeCaloriesBurned + bmrCaloriesBurned) - deficitGoal - caloriesConsumed;
  }

  return (
    <div className="App">
      <Grid container spacing={1} columns={3} sx={{mb: 1}}>
        <Grid item xs={1}>{DashboardDataCard(dashboardData?.data?.activeCaloriesBurned, "Active Burned", dashboardIsLoading, dashError?.data)}</Grid>
        <Grid item xs={1}>{DashboardDataCard(dashboardData?.data?.bmrCaloriesBurned, "BMR Burned", dashboardIsLoading, dashError?.data)}</Grid>
        <Grid item xs={1}>{DashboardDataCard(caloriesConsumed, "Consumed", dailyNutritionIsLoading, dnError?.data)}</Grid>
      </Grid>
      <Grid container spacing={1} columns={2}>
        <Grid item xs={1}>{DashboardDataCard(remainingCalories, "Remaining", dashboardIsLoading || dailyNutritionIsLoading, dashError?.data ?? dnError?.data)}</Grid>
        <Grid item xs={1}>{DashboardDataCard(remainingCaloriesWithDeficitGoal, `Remaining - ${deficitGoal}`, dashboardIsLoading || dailyNutritionIsLoading, dashError?.data ?? dnError?.data)}</Grid>
      </Grid>
      <Grid container spacing={1} columns={2}>
        <Grid item xs={1}>
          <Box sx={{m: 1}}>{ReadinessIcon(dashboardData?.data?.readinessScore)}</Box>
        </Grid>
        <Grid item xs={1}>
          <Box sx={{m: 1}}>{SleepIcon(dashboardData?.data?.sleepScore)}</Box>
        </Grid>
      </Grid>
      <Fab size="small" aria-label="refresh" onClick={() => { window.location.reload(); }} style={{
          position: "fixed",
          bottom: theme.spacing(2),
          right: theme.spacing(2),
        }}
      ><RefreshIcon /></Fab>
    </div>
  );
}

function DashboardDataCard(metric: number | undefined, label: string, isLoading: boolean = true, error: string | undefined) {
  let dataToDisplay : number | string | undefined;
  let color:string;
  let variant:Variant;

  if (error) {
    dataToDisplay = error;
    color = 'red';
    variant = 'body1'
  } else {
    dataToDisplay = Math.round(metric ?? 0);
    color = dataToDisplay < 0 ? 'red' : 'palette.text.primary';
    variant = 'h1';
  }

  return (
    <Card variant="outlined">
      <CardContent>
        <Typography variant='h4' color="text.secondary" gutterBottom>{label}</Typography>
        {isLoading && <CircularProgress size={50} />}
        {!isLoading && <Typography variant={variant} component="div" color={color}>{dataToDisplay}</Typography>}
      </CardContent>
    </Card>
  );
}

function ReadinessIcon(score?: number) {
  const fontSize: number = 60;
  if (score) {
    if (score <= 69) {
      const color = 'error';
      if (score <= 9) {
        return <Battery0BarIcon color={color} sx={{ fontSize: fontSize, transform: "rotate(90deg);" }} />
      } else if (score >= 10 && score <= 19) {
        return <Battery1BarIcon color={color}  sx={{ fontSize: fontSize, transform: "rotate(90deg);" }} />
      } else if (score >= 20 && score <= 29) {
        return <Battery2BarIcon color={color}  sx={{ fontSize: fontSize, transform: "rotate(90deg);" }} />
      } else if (score >= 30 && score <= 39) {
        return <Battery3BarIcon color={color}  sx={{ fontSize: fontSize, transform: "rotate(90deg);" }} />
      } else if (score >= 40 && score <= 49) {
        return <Battery4BarIcon color={color}  sx={{ fontSize: fontSize, transform: "rotate(90deg);" }} />
      } else {
        return <Battery5BarIcon color={color} sx={{ fontSize: fontSize, transform: "rotate(90deg);" }} />
      }
    } else if (score <= 84 && score >= 70) {
      return <Battery6BarIcon color='warning' sx={{ fontSize: fontSize, transform: "rotate(90deg);" }} />
    } else {
      return <BatteryFullIcon color='success' sx={{ fontSize: fontSize, transform: "rotate(90deg);" }} />
    }
  } else {
    return <Battery0BarIcon color='disabled' sx={{ fontSize: fontSize, transform: "rotate(90deg);" }} />
  }
}

function SleepIcon(score?: number) {
  const fontSize: number = 56;
  if (score) {
    if (score <= 69) {
      return <HotelIcon color='error' sx={{ fontSize: fontSize }} />
    } else if (score <= 84 && score >= 70) {
      return <HotelIcon color='warning' sx={{ fontSize: fontSize }} />
    } else {
      return <HotelIcon color='success' sx={{ fontSize: fontSize }} />
    }
  } else {
    return <HotelIcon color='disabled' sx={{ fontSize: fontSize }} />
  }
}

const delay = (ms: number | undefined) => new Promise(
  resolve => setTimeout(resolve, ms)
);

export default App;
