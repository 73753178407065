import { getAuth, signInWithCredential, OAuthCredential, GoogleAuthProvider, GithubAuthProvider } from "firebase/auth";
import axios, {AxiosResponse, AxiosRequestConfig} from 'axios';
import { FirebaseApp } from "firebase/app";

/**
 * Failure, success, etc. conditions for an Axios responses.
 * @param {AxiosResponse} response Axios response object.
 * @returns {Boolean} Does the repsonse meet certain conditions?
 */
type ResponseConditions = {
    (response: AxiosResponse): boolean;
}

type AuthenticationResponse = {
    url: string,
    code: string,
    device_code: string,
    interval: number,
    expires_in: number,
    [index: string]: any
}

type TokenResponse = {
    access_token: string,
    id_token?: string,
    [index: string]: any
}

/**
 * POSTs an endpoint repeatedly until either success or failure conditions are met.
 * @param {string} url - The URL of the endpoint to POST.
 * @param {Object} body - The body of the POST request.
 * @param {ResponseConditions} successConditions Success conditions. True if successful response, then this will return `response.data`.
 * @param {ResponseConditions} failureConditions Failure conditions. True if failed response, then this will throw `response`.
 * @param {number} interval - The delay in seconds between attempts. Defaults to 1 second.
 * @param {number} timeout - The maximum time in seconds to spend trying to fetch. Defaults to 10 seconds.
 * @param {AxiosRequestConfig} options - Options for the Axios POST handler. Defaults to requesting JSON and disable status code validation.
 * @returns {Promise<AxiosResponse>} The response.
*/
function repeatedPOST(url : string, body : Object, successConditions : ResponseConditions, failureConditions : ResponseConditions, interval : number = 1, timeout : number = 10, options : AxiosRequestConfig = {
    headers: {
        'Accept': 'application/json'
    },
    validateStatus: undefined
}) : Promise<AxiosResponse> {
    return new Promise<AxiosResponse>((resolve, reject) => {
        const _interval = setInterval(async () => {
            try {
                let response = await axios.post(url, body, options);
                if (successConditions(response)) {
                    end();
                    resolve(response);
                } else if (failureConditions(response)) {
                    end();
                    reject(new Error(String(response)));
                }
            } catch (err: any) {
                end();
                reject(err);
            }
        }, interval * 1000);
        const _timeout = setTimeout(() => {
            end();
            reject(new Error("Timed out. Check your internet connection."));
        }, timeout * 1000);
        let end = () => {
            clearInterval(_interval);
            clearTimeout(_timeout);
        }
    });
}

/**
 * Abstract base interface for all per-provider device flow managers.
 */
interface DeviceFlowManager {
    name : string,
    firebaseProvider : any,
    authorizationRequest(clientid : string, scopes : string[]) : Promise<AuthenticationResponse>,
    tokenRequest(authorizationResponse : AuthenticationResponse, clientid : string, clientsecret : string) : Promise<TokenResponse>,
    firebaseCredential(tokenResponse : TokenResponse) : OAuthCredential
}

class GoogleDeviceFlow implements DeviceFlowManager {
    private openid : any;
    readonly name : string = "Google";
    readonly firebaseProvider = GoogleAuthProvider;
    /**
     * Polls the Google OAuth Device Flow authorization endpoint for the device code, URL, etc.
     * @param {string} clientid The "TVs and Limited Input devices" OAuth 2.0 Client ID generated via the [GCP Console](https://console.developers.google.com/apis/credentials).
     * @param {string[]} scopes The scopes this token requires, from (this list)[https://developers.google.com/identity/protocols/oauth2/limited-input-device#allowedscopes].
     * @returns {Promise<AuthenticationResponse>} The response data.
     */
    async authorizationRequest(clientid : string, scopes : string[]) : Promise<AuthenticationResponse> {
        const response = await axios.get("https://accounts.google.com/.well-known/openid-configuration", {
            headers: {
                'Accept': 'application/json'
            }
        });
        //OK
        if (response.status === 200) {
            this.openid = response.data;
        } else {
            throw new Error("Could not fetch latest Google OpenID configuration! (HTTP Code " + response.status + ")");
        }
        const auth = await repeatedPOST(this.openid.device_authorization_endpoint, {
            'client_id': clientid,
            'scope': scopes.join(" ").toLowerCase()
        }, response_1 => {
            return [200].includes(response_1.status) && response_1.data.error === undefined;
        }, response_2 => {
            return [403].includes(response_2.status);
        });
        let result_1: AuthenticationResponse = auth.data;
        result_1.url = auth.data.verification_url;
        result_1.code = auth.data.user_code;
        return result_1;
    }
    /**
     * Polls the Google OAuth token endpoint for the access token until the user completes the authorization step.
     * @param {AuthenticationResponse} authorizationResponse The response data from the authorization step.
     * @param {String} clientid The "TVs and Limited Input devices" OAuth 2.0 Client ID generated via the [GCP Console](https://console.developers.google.com/apis/credentials). 
     * @param {String} clientsecret The "TVs and Limited Input devices" OAuth 2.0 Client Secret generated via the [GCP Console](https://console.developers.google.com/apis/credentials).
     * @returns {Promise<TokenResponse>} The response data.
     */
    async tokenRequest(authorizationResponse : AuthenticationResponse, clientid : string, clientsecret : string) : Promise<TokenResponse>{
        const token = await repeatedPOST(this.openid.token_endpoint, {
            'client_id': clientid,
            'client_secret': clientsecret,
            'device_code': authorizationResponse.device_code,
            'grant_type': (this.openid.grant_types_supported as Array<string>).filter(grant_type => {
                return grant_type.includes("device_code");
            })[0]
        }, response => {
            return [200].includes(response.status) && response.data.error === undefined;
        }, response_1 => {
            return [400, 401, 403].includes(response_1.status) && ['invalid_client', 'access_denied', 'slow_down', 'invalid_grant', 'unsupported_grant_type'].includes(response_1.data.error);
        }, authorizationResponse.interval + 1, authorizationResponse.expires_in);
        return (token.data as TokenResponse);
    }

    /**
     * Builds and returns a Google-provider Firebase credential out of the token step's response.
     * @param {TokenResponse} tokenResponse - The response from the token step.
     * @returns {OAuthCredential} The Firebase credential.
     */
    firebaseCredential(tokenResponse : TokenResponse) : OAuthCredential{
        return this.firebaseProvider.credential(tokenResponse.id_token, tokenResponse.access_token);
    }
}

class GitHubDeviceFlow implements DeviceFlowManager {
    readonly name : string = "GitHub";
    readonly firebaseProvider = GithubAuthProvider;
    /**
     * Polls the Github OAuth Device Flow authorization endpoint for the device code, URL, etc.
     * @param {string} clientid The OAuth App Client ID generated via the [GitHub Developer settings panel](https://github.com/settings/developers).
     * @param {string[]} scopes The scopes this token requires, from (this list)[https://docs.github.com/en/free-pro-team@latest/developers/apps/scopes-for-oauth-apps].
     * @returns {Promise<AuthenticationResponse>} The response data.
     */
    async authorizationRequest(clientid : string, scopes : string[]) : Promise<AuthenticationResponse> {
        const auth = await repeatedPOST('https://github.com/login/device/code', {
            'client_id': clientid,
            'scope': scopes.join(" ").toLowerCase()
        }, response => {
            return [200].includes(response.status) && response.data.error === undefined;
        }, response_1 => {
            return [403].includes(response_1.status);
        });
        let response_2: AuthenticationResponse = auth.data;
        response_2.url = auth.data.verification_uri;
        response_2.code = auth.data.user_code;
        return response_2;
    }
    /**
     * Polls the Github OAuth token endpoint for the access token until the user completes the authorization step.
     * @param {AuthenticationResponse} authorizationResponse The response data from the authorization step.
     * @param {string} clientid The OAuth App Client ID generated via the [GitHub Developer settings panel](https://github.com/settings/developers).
     * @param {string} clientsecret Unused.
     * @returns {Promise<TokenResponse>} The response data.
     */
    async tokenRequest(authorizationResponse : AuthenticationResponse, clientid : string) : Promise<TokenResponse>{
        const token = await repeatedPOST('https://github.com/login/oauth/access_token', {
            'client_id': clientid,
            'device_code': authorizationResponse.device_code,
            'grant_type': 'urn:ietf:params:oauth:grant-type:device_code'
        }, response => {
            return [200].includes(response.status) && response.data.error === undefined;
        }, response_1 => {
            // console.log(response)
            return ['expired_token', 'unsupported_grant_type', 'incorrect_client_credentials', 'incorrect_device_code', 'access_denied'].includes(response_1.data.error);
        }, authorizationResponse.interval + 1, authorizationResponse.expires_in);
        let result: TokenResponse = token.data;
        return result;
    }
    /**
     * Builds and returns a GitHub-provider Firebase credential out of the token step's response.
     * @param {TokenResponse} tokenResponse - The response from the token step.
     * @returns {OAuthCredential} The Firebase credential.
     */
    firebaseCredential(tokenResponse : TokenResponse): OAuthCredential {
        return this.firebaseProvider.credential(tokenResponse.access_token);
    }
}

function stringTuple<T extends [string] | string[]>(...data: T): T {
    return data;
}

// To add a new provider: add printed name to the `stringTuple` args, device flow object to `TProviderMap` union and `ProviderMap` const, url entry to ProviderURLMap

const ProviderNames = stringTuple('Google', 'GitHub');
type TProviderID = typeof ProviderNames[number];
type TProviderMap = {
    [key in TProviderID]: typeof GoogleDeviceFlow | typeof GitHubDeviceFlow
}

const ProviderMap : TProviderMap = {
    Google: GoogleDeviceFlow,
    GitHub: GitHubDeviceFlow
}

// The DeviceFlowUI options object, indexed by that enum of strings
export type DeviceFlowUIOptions = {
    [key in TProviderID]?: {
        clientid?: string,
        clientsecret?: string,
        scopes?: string[]
    }
}

export interface DeviceFlowContext {
    authenticationResponse: AuthenticationResponse,
    provider: GoogleDeviceFlow | GitHubDeviceFlow,
    providerId: TProviderID,
    options: DeviceFlowUIOptions
    tokenResponse: TokenResponse | undefined
}

export async function startDeviceAuthFlow(providerId : TProviderID, options: DeviceFlowUIOptions) : Promise<DeviceFlowContext> {
    var provider = new ProviderMap[providerId]();
    //Authenticate with Firebase
    try {
        //Get login code
        const authenticationResponse = await provider.authorizationRequest(options[providerId]?.clientid as string, options[providerId]?.scopes as string[]);
        return {
            authenticationResponse: authenticationResponse,
            provider: provider,
            providerId: providerId,
            options: options,
            tokenResponse: undefined
        }
    } catch (err: any) {
        if (err.data && err.data.error) {
            throw new Error(`Fetching ${provider.name} Device Code & URL Failed! (Code ${err.status} - ${err.data.error})`);
        } else if(err.stus){
            throw new Error(`Fetching ${provider.name} Device Code & URL Failed! (Code ${err.status})`);
        } else {
            throw new Error(`Fetching ${provider.name} Device Code & URL Failed!`);
        }
    }
}

export async function waitForToken(deviceFlowContext: DeviceFlowContext) : Promise<DeviceFlowContext> {
    try {
        const tokenResponse = await deviceFlowContext.provider.tokenRequest(deviceFlowContext.authenticationResponse, deviceFlowContext.options[deviceFlowContext.providerId]?.clientid as string, deviceFlowContext.options[deviceFlowContext.providerId]?.clientsecret as string);
        deviceFlowContext.tokenResponse = tokenResponse;
        return deviceFlowContext;
    } catch (err: any) {
        //General errors
        if (err.data && err.data.error) {
            throw new Error(`${deviceFlowContext.provider.name} Authorization & Token Fetch Failed! (Code  ${err.status} - ${err.data.error})`);
        } else if(err.status){
            throw new Error(`${deviceFlowContext.provider.name} Authorization & Token Fetch Failed! (Code  ${err.status})`);
        } else {
            throw new Error(`${deviceFlowContext.provider.name} Authorization & Token Fetch Failed!`);
        }
    }
}

export async function authWithFirebase(app: FirebaseApp, deviceFlowContext: DeviceFlowContext) {
    try {
        if (deviceFlowContext.tokenResponse) {
            return await signInWithCredential(getAuth(app), deviceFlowContext.provider.firebaseCredential(deviceFlowContext.tokenResponse));
        } else {
            throw new Error('Token response is null or empty!');
        }
    } catch (err: any) {
        throw new Error(err.message);
    }
}