import { Box, Button, Grid, Typography } from '@mui/material';
import NotFoudImage from '../../assets/404.gif';
import { Link, useLocation } from 'react-router-dom';
import { paths } from '../../components/Router';

export function NotFound() {
  const location = useLocation();
  
  return (
    <Grid container direction="column" alignItems="center" spacing={0} style={{ minHeight: '100vh' }}>
      <Grid item>
        <img src={NotFoudImage} alt="404 error" />
      </Grid>
      <Grid item>
        <Typography variant="h1" noWrap align="center">404</Typography>
      </Grid>
      <Grid item>
        <Typography variant="h6" noWrap align="center">The requested URL <b>{location.pathname}</b> was not found</Typography>
      </Grid>
      <Grid item>
        <Box marginTop="1em">
          <Button 
            variant="contained"
            color="primary"
            component={Link} 
            to={paths.ROOT}
          >
            Go Back
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
}