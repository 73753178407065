import React from 'react';
import { useStore } from 'react-redux';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { RootState } from '../app/store';
import { isAuthed } from '../features/auth/authSlice';
import { paths } from './Router';

interface PrivateRouteProps extends RouteProps {
  component: any;
}

const PrivateRoute = (props: PrivateRouteProps) => {
  const { component: Component, ...rest } = props;
  
  const store = useStore<RootState>();

  return (
    <Route
      {...rest}
      render={(routeProps) =>
        isAuthed(store.getState()) ? (
          <Component {...routeProps} />
        ) : (
          <Redirect
            to={{
              pathname: paths.SIGNIN,
              state: { from: routeProps.location },
            }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;