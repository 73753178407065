import { createApi, fetchBaseQuery, FetchBaseQueryError, FetchBaseQueryMeta } from '@reduxjs/toolkit/query/react';
import { getAuth } from 'firebase/auth';
import firebaseApp from '../../../firebase';

interface CronometerResponse {
  status?: string;
  data?: string;
}

// Create our baseQuery instance
const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_CRONOMETER_URL,
  prepareHeaders: async (headers) => {
    const token = await getAuth(firebaseApp).currentUser?.getIdToken();
    if (token) {
      headers.set('Authorization', `Bearer ${token}`)
    }
    return headers
  },
});


export const cronometerApi = createApi({
  reducerPath: 'chronometerApi',
  baseQuery: baseQuery,
  tagTypes: ['Cronometer'],
  endpoints: (builder) => ({
    getDailyNutrution: builder.query<CronometerResponse, void>({
      query: () => ({
        url: 'daily-nutrition'
      }),
      transformErrorResponse: (baseQueryReturnValue: FetchBaseQueryError, meta: FetchBaseQueryMeta | undefined, arg: void) => {
        let data: CronometerResponse | undefined = undefined;
        if (baseQueryReturnValue?.data) {
          data = baseQueryReturnValue.data as CronometerResponse;
        }

        return {
          status: baseQueryReturnValue.status ?? 500,
          statusText: "",
          data: data?.data ?? "Could not fetch Cronometer data."
        }
      }
    }),
  }),
})

export const { useGetDailyNutrutionQuery } = cronometerApi