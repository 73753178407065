import * as React from 'react';
import { shallowEqual } from 'react-redux';
import { resetAuth, signInWithGoogle, startAuthFlow } from './authSlice';
import { RootState } from '../../app/store';
import { useEffect } from 'react';
import { AuthStatus } from '../../app/models/AuthStatus';
import { useSnackbar } from 'notistack';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { Avatar, CircularProgress, Container, Card, Typography, CardContent, Box } from '@mui/material';
import { Redirect, useLocation } from 'react-router-dom';
import { paths } from '../../components/Router';
import LockIcon from '@mui/icons-material/Lock';
import GoogleIcon from '@mui/icons-material/Google';
import LoadingButton from '@mui/lab/LoadingButton';

interface LocationState {
  from: {
    pathname: string;
  };
}

export const SignIn = () => {
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const { error, loading, isAuthed, url, code } = useAppSelector(
    (state: RootState) => ({
      error: state.auth.error,
      loading: state.auth.loading,
      isAuthed: state.auth.status === AuthStatus.success,
      url: state.auth.url,
      code: state.auth.code,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (error) {
      enqueueSnackbar(error, {
        variant: 'error',
        preventDuplicate: true,
      });
      dispatch(resetAuth());
      dispatch(startAuthFlow());
    }
  }, [error, dispatch, enqueueSnackbar, isAuthed]);

  let location = useLocation<LocationState>();
  let { from } = location.state || { from: { pathname: paths.ROOT } };

  function handleClick() {
    dispatch(signInWithGoogle());
  }

  return (
    <Container component="main" maxWidth="sm" sx={{ mt: 4, textAlign: 'center' }}>
      {isAuthed && <Redirect to={from} />}
      <Card variant="outlined">
        <CardContent>
          <Box display="flex" justifyContent="center" alignItems="center" sx={{ mb: 3, mt: 2 }}>
            {loading && <CircularProgress />}
            {!loading && <Avatar><LockIcon /></Avatar>}
          </Box>
          <Box sx={{ mb: 5 }}>
            <Typography variant="h4">Use the button or URL & code to sign in. Once signed in, this page will refresh.</Typography>
          </Box>
          <Box sx={{ mb: 5 }}>
            <LoadingButton
              onClick={handleClick}
              loading={loading}
              loadingPosition="start"
              startIcon={<GoogleIcon />}
              variant="outlined"
            >
            Sign in with Google
            </LoadingButton>
          </Box>   
          <Typography variant="h4" gutterBottom>{url}</Typography>
          <Typography variant="h3" sx={{ letterSpacing: 6 }}>{code}</Typography>
        </CardContent>
      </Card>
    </Container>
  );
}

export default SignIn;