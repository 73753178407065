import { createApi, fakeBaseQuery } from '@reduxjs/toolkit/query/react'
import { getFunctions, httpsCallable } from 'firebase/functions';
import firebaseApp from '../../../firebase';
import { ApiError } from '../../models/ApiError';
import { Response } from '../../models/Response ';

export const versionApi = createApi({
  reducerPath: 'versionApi',
  baseQuery: fakeBaseQuery<ApiError>(),
  tagTypes: ['Version'],
  endpoints: (builder) => ({
    getVersion: builder.query<Response, void>({
      async queryFn() {
        try {
          const versionFunction = httpsCallable<void, string>(getFunctions(firebaseApp), 'https-config-getVersionV1',);
          const version = await versionFunction();
          return { 
            data: JSON.parse(version.data)
          }
        } catch (e: any) {
          return {
            error: {
              status: 500,
              statusText: 'Internal Server Error',
              data: e.message ?? 'Could not fetch dashboard data.',
            },
          }
        } 
      },
    }),
  }),
});

export const {
  useLazyGetVersionQuery,
} = versionApi