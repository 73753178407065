import { createApi, fakeBaseQuery } from '@reduxjs/toolkit/query/react'
import { getFunctions, httpsCallable } from 'firebase/functions';
import firebaseApp from '../../../firebase';
import { ApiError } from '../../models/ApiError';
import { Response } from '../../models/Response ';

export const dashboardApi = createApi({
  reducerPath: 'dashboardApi',
  baseQuery: fakeBaseQuery<ApiError>(),
  tagTypes: ['Dashboard'],
  endpoints: (builder) => ({
    getDashboardData: builder.query<Response, void>({
      async queryFn() {
        try {
          const dashboardDataFunction = httpsCallable<void, string>(getFunctions(firebaseApp), 'https-dashboard-dataV1',);
          const dashboardData = await dashboardDataFunction();
          return { 
            data: JSON.parse(dashboardData.data)
          }
        } catch (e: any) {
          return {
            error: {
              status: 500,
              statusText: 'Internal Server Error',
              data: e.message ?? 'Could not fetch dashboard data.',
            },
          }
        }
      },
    }),
  }),
});

export const {
  useGetDashboardDataQuery,
} = dashboardApi