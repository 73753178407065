import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getAuth, GoogleAuthProvider, onAuthStateChanged, signInWithPopup } from 'firebase/auth';
import { Dispatch } from 'react';
import { AuthStatus } from '../../app/models/AuthStatus';
import { RootState } from '../../app/store';
import firebaseApp from '../../firebase';
import { authWithFirebase, DeviceFlowContext, startDeviceAuthFlow, waitForToken } from './device-auth-flow/FirebaseDeviceFlow';

interface AuthState {
  userId: string | undefined,
  status: AuthStatus,
  loading: boolean,
  error: string | undefined,
  restoring: boolean,
  restoringError: boolean | undefined,
  url: string | undefined,
  code: string | undefined,
};

const initialState: AuthState = { 
  userId: undefined, 
  status: AuthStatus.unknown,
  loading: false,
  error: undefined,
  restoring: false,
  restoringError: undefined,
  url: undefined,
  code: undefined,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    signInInit: (state) => {
      state.loading = true;
    },
    signInFail: (state, action: PayloadAction<{errorMessage: string}>) => {
      state.loading = false;
      state.error = action.payload.errorMessage;
      state.status = AuthStatus.error;
    },
    authFlowInit: (state) => {
      state.loading = true;
    },
    authFlowSetup: (state, action: PayloadAction<{url: string, code: string}>) => {
      state.loading = false;
      state.url = action.payload.url;
      state.code = action.payload.code;
    },
    authFlowFail: (state, action: PayloadAction<{errorMessage: string}>) => {
      state.loading = false;
      state.error = action.payload.errorMessage;
      state.status = AuthStatus.error;
    },
    signOutInit: (state) => {
      return initialState;
    },
    signOutSuccess: (state) => {
      return initialState;
    },
    restoreAuthInit: (state) => {
      state.restoring = true;
      state.restoringError = undefined;
      state.error = undefined;
    },
    restoreAuthSuccess: (state, action: PayloadAction<{userId: string}>) => {
      state.restoring = false;
      state.restoringError = undefined;
      state.status = AuthStatus.success;
      state.userId = action.payload.userId;
    },
    restoreAuthFail: (state, action: PayloadAction<{errorMessage: string | undefined}>) => {
      state.restoring = false;
      state.restoringError = true;
      state.status = AuthStatus.unknown;
      state.error = action.payload.errorMessage;
    },
    resetAuth: (state) => {
      // https://redux-toolkit.js.org/usage/immer-reducers#resetting-and-replacing-state
      return initialState;
    }
  },
});

export const { signInInit, signInFail, authFlowInit, authFlowFail, authFlowSetup, signOutInit, signOutSuccess, restoreAuthInit, restoreAuthSuccess, restoreAuthFail, resetAuth } = authSlice.actions;

export const isAuthed = (state: RootState) => {
  return state.auth.status === AuthStatus.success;
};

export default authSlice.reducer;

export const signInWithGoogle = () => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(signInInit());
    try {
      return await signInWithPopup(getAuth(), new GoogleAuthProvider());
    } catch (error: any) {
      return dispatch(signInFail({ errorMessage: error.message }));
    }
  }
}

export const startAuthFlow = () => {
  return async (dispatch: Dispatch<any>) => {
    const providerId = "Google";

    const options = {
      "Google": {
        clientid: "142076398480-ipqtu8kgdbqclo9ih3ihlq3nprtk31lo.apps.googleusercontent.com",
        clientsecret: "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",
        scopes: ['email']
      }
    }

    try {
      dispatch(authFlowInit());
      let deviceFlowContext : DeviceFlowContext = await startDeviceAuthFlow(providerId, options);
      if (deviceFlowContext.authenticationResponse) {
        dispatch(authFlowSetup({url: deviceFlowContext.authenticationResponse.url, code: deviceFlowContext.authenticationResponse.code}));
        deviceFlowContext = await waitForToken(deviceFlowContext);
        await authWithFirebase(firebaseApp, deviceFlowContext);
      } else {
        throw new Error('Could not get auth flow url or code');
      }
    } catch (error: any) {
      return dispatch(authFlowFail({ errorMessage: error.message }));
    }    
  }
}

export const verifyAuth = () => {
  return (dispatch: Dispatch<any>) => {   
    onAuthStateChanged(getAuth(firebaseApp), async (user) => {
      dispatch(restoreAuthInit());
      
      let message: string | undefined;
      if (user !== null) {
        // I only want to allow my personal email to be able to call this API so some random person doesn't start abusing it.
        if (user.email === 'jonjon1123@gmail.com') {
          return dispatch(restoreAuthSuccess({ userId: user.uid }));
        } else {
          message = 'User is not authroized to use this application.';
        }
      }
  
      dispatch(startAuthFlow());
      return dispatch(restoreAuthFail({ errorMessage: message }));
    })
  }
}

export const signOut = () => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(signOutInit());
    await getAuth(firebaseApp).signOut();
    dispatch(signOutSuccess());
  }
}